// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CostCollector_priceForm_item__QZbV0 {
  min-width: 400px;
}

.CostCollector_formButton__D6Uqf {
  min-width: 300px;
}

.CostCollector_activeRow__GGrqE {
  background-color: #b7eb8f !important;
}
.CostCollector_activeRow__GGrqE:hover {
  background-color: #b7eb8f !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/CostCollector/CostCollector.module.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,oCAAA;AAEF;AADE;EACE,oCAAA;AAGJ","sourcesContent":[".priceForm\n  &_item\n    min-width: 400px\n\n.formButton\n  min-width: 300px\n\n.activeRow\n  background-color: #b7eb8f !important\n  &:hover\n    background-color: #b7eb8f !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"priceForm_item": `CostCollector_priceForm_item__QZbV0`,
	"formButton": `CostCollector_formButton__D6Uqf`,
	"activeRow": `CostCollector_activeRow__GGrqE`
};
export default ___CSS_LOADER_EXPORT___;
