import { useState } from "react"

type Paginator = {
    allPages: number
    currentPage: number
    pageSize: number
    totalCount: number
}

const defaultPaginator = {
    allPages: 0,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
}

export const usePaginator = () => {
    const [paginator, setPaginator] = useState<Paginator>(defaultPaginator)

    return {
        paginator,
        setPaginator,
    }
}
