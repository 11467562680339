// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UNumberItem_noBackground__j4YwH {
  background: none;
}

.UNumberItem_not-bordered__-O\\+RC {
  border: none;
}

.UNumberItem_wrapper__vq1Ow {
  position: relative;
}

.UNumberItem_prefix__6VkKy {
  position: absolute;
  left: 10px;
  font-size: 14px;
  bottom: 6px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/UNumberItem.module.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;AAIF","sourcesContent":[".noBackground\n  background: none\n\n.not-bordered\n  border: none\n\n.wrapper\n  position: relative\n\n.prefix\n  position: absolute\n  left: 10px\n  font-size: 14px\n  bottom: 6px\n  font-weight: bold"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noBackground": `UNumberItem_noBackground__j4YwH`,
	"not-bordered": `UNumberItem_not-bordered__-O+RC`,
	"wrapper": `UNumberItem_wrapper__vq1Ow`,
	"prefix": `UNumberItem_prefix__6VkKy`
};
export default ___CSS_LOADER_EXPORT___;
