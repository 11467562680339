import { Row } from "antd"
import Table, { ColumnsType } from "antd/es/table"
import { TableRowSelection } from "antd/es/table/interface"
import { useEffect, useMemo,  useState } from "react"
import DebounceInput from "./DebouncedInput"

type DataTableProps<T extends object> = {
    data: T[]
    loading?: boolean
    noSearch?: boolean
    searchKeys?: Array<keyof T>
    columns: ColumnsType<T>
    rowKey: keyof T
    onSelectRow?: (value: T) => void
    noRowSelection?: boolean
}

export default function DataTable<T extends object>({
    data,
    loading,
    noSearch,
    searchKeys,
    columns,
    rowKey,
    onSelectRow,
    noRowSelection,
}: DataTableProps<T>) {
    const [search, setSearch] = useState("")
    const [selectedRows, setSelectedRows] = useState<T[]>([])
    const [tableKey, setTableKey] = useState(Date.now());

    const rowSelection: TableRowSelection<T> = {
        selectedRowKeys: selectedRows.map((el) => el[rowKey] as string),
        renderCell() {
            return null
        },
        columnWidth: 0,
    }

    const filteredRows = useMemo(() => {
        if (!searchKeys) return data
        setTableKey(Date.now());
        return search
            ? data.filter((row) =>
                  searchKeys.some((key) => {
                      const value = "" + row[key]
                      return value.toLowerCase().includes(search.toLowerCase())
                  })
              )
            : data
    }, [data, search])

    useEffect(() => {
        if (selectedRows.length && onSelectRow) {
            onSelectRow(selectedRows[0])
        }
    }, [selectedRows])

    return (
        <Row gutter={[5, 5]}>
            {!noSearch && (
                <DebounceInput initValue="" resolve={(v: string) => setSearch(v)} inputProps={{
                    type: 'text',
                    placeholder: 'Поиск'
                }}/>
            )}

            <Table
                key={tableKey}
                loading={loading}
                columns={columns}
                dataSource={filteredRows}
                bordered
                rowClassName={'data-table-row'}
                rowKey={(data: T) => data[rowKey] as string}
                size="middle"
                pagination={false}
                scroll={{ y: 450 }}
                rowSelection={noRowSelection ? undefined : rowSelection}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            setSelectedRows((prev) =>
                                prev.some((el) => el[rowKey] === record[rowKey])
                                    ? []
                                    : [record]
                            )
                        },
                    }
                }}
            />
        </Row>
    )
}
