// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StandartKitDetail_root__pE85O a {
  font-size: 16px;
}

.StandartKitDetail_picture__\\+pWLv {
  width: 100%;
  min-height: 500px;
  position: relative;
}

.StandartKitDetail_hidden__Izv4U {
  display: none;
  opacity: 0;
}

.StandartKitDetail_image__\\+2yiw {
  display: block;
  opacity: 1;
  transition: 200ms ease;
  width: 100%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/StandardKitDetail/StandartKitDetail.module.sass"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAEA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AACA;EACE,aAAA;EACA,UAAA;AAEF;;AAAA;EACE,cAAA;EACA,UAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AAGF","sourcesContent":[".root\n  a\n    font-size: 16px\n\n.picture\n  width: 100%\n  min-height: 500px\n  position: relative\n\n.hidden\n  display: none\n  opacity: 0\n\n.image\n  display: block\n  opacity: 1\n  transition: 200ms ease\n  width: 100%\n  height: auto"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `StandartKitDetail_root__pE85O`,
	"picture": `StandartKitDetail_picture__+pWLv`,
	"hidden": `StandartKitDetail_hidden__Izv4U`,
	"image": `StandartKitDetail_image__+2yiw`
};
export default ___CSS_LOADER_EXPORT___;
