// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CostCollectorAttributes_disabledCell__EC86t {
  background-color: #c2bfbf;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.CostCollectorAttributes_input__dQ-yH {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/CostCollector/CostCollectorAttributes/CostCollectorAttributes.module.sass","webpack://./src/styles/paritals/_flex.sass"],"names":[],"mappings":"AAEA;EACE,yBAAA;ECFE,aAAA;EACA,eAAA;EACA,mBDCI;ECAJ,uBAAA;EACA,mBDDiB;ECEjB,QAN4C;ADQhD;;AAFA;EACE,WAAA;AAKF","sourcesContent":["@import ../../../styles/paritals/_flex\n  \n.disabledCell\n  background-color: #c2bfbf\n  +flex(row, center, center)\n\n.input\n  width: 100%","=flex($fd: row, $jc: center, $ai: center, $gap: 0px)\n    display: flex\n    flex-wrap: wrap\n    flex-direction: $fd\n    justify-content: $jc\n    align-items: $ai\n    gap: $gap\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabledCell": `CostCollectorAttributes_disabledCell__EC86t`,
	"input": `CostCollectorAttributes_input__dQ-yH`
};
export default ___CSS_LOADER_EXPORT___;
