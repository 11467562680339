import api from "helpers/api"
import { useNotifications } from "hooks/useNotifications"
import { usePageId } from "hooks/usePageId"
import { useEffect, useState } from "react"
import classes from "./NomenclatureDetail.module.sass"

type ApiState = {
    error: string | null
    loading: boolean
    success: boolean
}

const buttonClassName =
    "fw d-flex align-center justify-center btn btn-primary btn-sm"

export const AddToRebbit = () => {
    const { showNotification } = useNotifications()

    const { id } = usePageId()

    const [addNomenclatureState, setAddNomenclatureState] = useState<ApiState>({
        error: null,
        loading: false,
        success: false,
    })

    const [addPricesState, setAddPricesState] = useState<ApiState>({
        error: null,
        loading: false,
        success: false,
    })

    const saveToRabbit = (target: "nomenclature" | "price") => async () => {
        const saveState =
            target === "nomenclature"
                ? setAddNomenclatureState
                : setAddPricesState
        saveState(() => ({
            loading: true,
            error: null,
            success: false,
        }))
        try {
            await api.get(
                `v1/nomenclature/${id}/${
                    target === "price" ? "prices/" : ""
                }rabbit/push`
            )
            saveState((prev) => ({
                ...prev,
                success: true,
            }))
        } catch (error: any) {
            saveState((prev) => ({
                ...prev,
                error: error.message,
            }))
        } finally {
            saveState((prev) => ({
                ...prev,
                loading: false,
            }))
        }
    }

    useEffect(() => {
        const error = addNomenclatureState.error || addPricesState.error
        if (error) {
            showNotification({
                message: error,
                type: "danger",
            })
        }
    }, [showNotification, addNomenclatureState.error, addPricesState.error])

    useEffect(() => {
        const success = addNomenclatureState.success || addPricesState.success
        if (success) {
            showNotification({
                message: "Данные успешно отправлены",
                type: "danger",
            })
        }
    }, [showNotification, addNomenclatureState.success, addPricesState.success])

    return (
        <div className={classes.addToRebbit}>
            <button
                className={buttonClassName}
                onClick={saveToRabbit("nomenclature")}
            >
                {addNomenclatureState.loading && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                {!addNomenclatureState.loading && (
                    <span>Отправить номеклатуру в рэббит</span>
                )}
            </button>
            <button className={buttonClassName} onClick={saveToRabbit("price")}>
                {addPricesState.loading && (
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                )}
                {!addPricesState.loading && (
                    <span> Отправить цены в рэббит</span>
                )}
            </button>
        </div>
    )
}
