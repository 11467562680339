// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-toggle-btn {
  display: inline;
  cursor: pointer;
}

.tree-toggle-btn:hover {
  opacity: 0.5;
}

.tree-node-name > * {
  text-decoration: none;
  color: #000;
}

.tree-node-opened a {
  color: #0d6efd;
}

.tree-node-name {
  display: inline;
}

.tree-icon-empty {
  width: 19px;
  display: inline-block;
}

ul.tree {
  border-left: 1px dashed rgba(0, 0, 0, 0.4);
  margin-left: 9px;
  padding-left: 15px;
}

ul.tree:first-child {
  border-left: none;
}

.tree-wrapper > .tree > li:first-of-type {
  margin-left: -22px;
}

.tree-append-child {
  color: #000;
  text-decoration: none;
  margin-left: 10px;
}

a.tree-append-child:hover {
  color: #000;
  opacity: 0.5;
}

.tree-remove-child {
  color: #000;
  text-decoration: none;
  margin-left: 5px;
}

.tree li {
  list-style: none;
  /* padding-top: 8px; */
}

.tree svg {
  width: 19px;
}
`, "",{"version":3,"sources":["webpack://./src/partials/Tree/tree.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".tree-toggle-btn {\n  display: inline;\n  cursor: pointer;\n}\n\n.tree-toggle-btn:hover {\n  opacity: 0.5;\n}\n\n.tree-node-name > * {\n  text-decoration: none;\n  color: #000;\n}\n\n.tree-node-opened a {\n  color: #0d6efd;\n}\n\n.tree-node-name {\n  display: inline;\n}\n\n.tree-icon-empty {\n  width: 19px;\n  display: inline-block;\n}\n\nul.tree {\n  border-left: 1px dashed rgba(0, 0, 0, 0.4);\n  margin-left: 9px;\n  padding-left: 15px;\n}\n\nul.tree:first-child {\n  border-left: none;\n}\n\n.tree-wrapper > .tree > li:first-of-type {\n  margin-left: -22px;\n}\n\n.tree-append-child {\n  color: #000;\n  text-decoration: none;\n  margin-left: 10px;\n}\n\na.tree-append-child:hover {\n  color: #000;\n  opacity: 0.5;\n}\n\n.tree-remove-child {\n  color: #000;\n  text-decoration: none;\n  margin-left: 5px;\n}\n\n.tree li {\n  list-style: none;\n  /* padding-top: 8px; */\n}\n\n.tree svg {\n  width: 19px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
