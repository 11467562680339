import React from 'react';
import { ModalWrapperProps } from '../../types/interfaces';

/**
 * Компонент. Пустое модальное окно.
 * @param title - Заголовок (prop)
 * @param modalID - ID, который должен быть таким же как и в элементе, который вызываает модалку (prop)
 * @param children - Содержимое модального окна (prop)
 */

export const ModalWrapper: React.FC<ModalWrapperProps> = props => {
  const { title, modalID, children } = props;

  return (
    <div className="modal fade" id={modalID} data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  )
}