// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StandartKitLogItem_collapse__QR7bE {
  cursor: pointer;
}

.StandartKitLogItem_collapseHeader__3qI3x {
  width: 100%;
  padding: 12px 16px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  border: 1px solid rgb(217, 217, 217);
}

.StandartKitLogItem_collapseBody__FJk\\+h {
  width: 100%;
  transition: all 1000ms ease;
  padding: 10px 20px;
  overflow: hidden;
}
.StandartKitLogItem_collapseBody__visible__y\\+\\+yy {
  opacity: 1;
  height: 100%;
  z-index: 10;
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgb(217, 217, 217);
  border-top: none;
}
.StandartKitLogItem_collapseBody__hidden__dnPEp {
  opacity: 0;
  height: 0%;
  padding: 0;
}

.StandartKitLogItem_logHeader__rgHrY {
  display: grid;
  width: 100%;
  grid-template-columns: 130px 1fr;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Kits/StandartKitLogItem.module.sass"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,+BAAA;EACA,kBAAA;EACA,oCAAA;AAEF;;AAAA;EACE,WAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;AAGF;AAFE;EACE,UAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;AAIJ;AAHE;EACE,UAAA;EACA,UAAA;EACA,UAAA;AAKJ;;AAHA;EACE,aAAA;EACA,WAAA;EACA,gCAAA;EACA,SAAA;AAMF","sourcesContent":[".collapse\n  cursor: pointer\n  \n.collapseHeader\n  width: 100%\n  padding: 12px 16px\n  background: rgba(0, 0, 0, 0.02)\n  border-radius: 8px\n  border: 1px solid rgb(217, 217, 217)\n\n.collapseBody\n  width: 100%\n  transition: all 1000ms ease\n  padding: 10px 20px\n  overflow: hidden\n  &__visible\n    opacity: 1\n    height: 100%\n    z-index: 10\n    background: #fff\n    border-radius: 2px\n    border: 1px solid rgb(217, 217, 217)\n    border-top: none\n  &__hidden\n    opacity: 0\n    height: 0%\n    padding: 0\n\n.logHeader\n  display: grid\n  width: 100%\n  grid-template-columns: 130px 1fr\n  gap: 10px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapse": `StandartKitLogItem_collapse__QR7bE`,
	"collapseHeader": `StandartKitLogItem_collapseHeader__3qI3x`,
	"collapseBody": `StandartKitLogItem_collapseBody__FJk+h`,
	"collapseBody__visible": `StandartKitLogItem_collapseBody__visible__y++yy`,
	"collapseBody__hidden": `StandartKitLogItem_collapseBody__hidden__dnPEp`,
	"logHeader": `StandartKitLogItem_logHeader__rgHrY`
};
export default ___CSS_LOADER_EXPORT___;
