// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculation-log__row {
  display: grid;
  grid-template-columns: 30px 1fr 1fr 1fr;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
  gap: 10px;
}
.calculation-log__row:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.calculation-log__row .line {
  text-align: left;
  opacity: 0.7;
}
.calculation-log__row .comment {
  text-align: left;
  opacity: 0.7;
}
.calculation-log__row .number {
  text-align: right;
}

.calculation-log__header {
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
}`, "",{"version":3,"sources":["webpack://./src/styles/components/nomenclature-calculation-log.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uCAAA;EACA,6BAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AACF;AACE;EACE,mBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACE;EACE,gBAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;EACA,YAAA;AACJ;AACE;EACE,iBAAA;AACJ;;AAOA;EACE,aAAA;EACA,oCAAA;AAJF","sourcesContent":[".calculation-log__row\n  display: grid\n  grid-template-columns: 30px 1fr 1fr 1fr\n  border-bottom: 1px solid #eee\n  padding-bottom: 5px\n  margin-bottom: 5px\n  gap: 10px\n\n  &:last-child\n    border-bottom: none\n    margin-bottom: 0\n    padding-bottom: 0\n\n  .line\n    text-align: left\n    opacity: .7\n\n  .comment\n    text-align: left\n    opacity: .7\n\n  .number\n    text-align: right\n\n\n  // .calculation-log__entities\n  //   .calculation-log__entity\n  //     &:first-child\n  //       margin-top: 10px\n\n.calculation-log__header\n  display: grid\n  grid-template-columns: 1fr 100px 1fr"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
