// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NomenclatureDetail_addToRebbit__RZTO7 {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
}`, "",{"version":3,"sources":["webpack://./src/pages/Nomenclature/NomenclatureDetail.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,8BAAA;AACF","sourcesContent":[".addToRebbit\n  display: grid\n  gap: 10px\n  grid-template-columns: 1fr 1fr"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToRebbit": `NomenclatureDetail_addToRebbit__RZTO7`
};
export default ___CSS_LOADER_EXPORT___;
