import { useQuery } from "@tanstack/react-query"
import { Select, SelectProps } from "antd"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { usePaginator } from "hooks/usePaginator"
import { useEffect, useState } from "react"
import { InputOptionType, Uuid } from "types"
import { ListingResponse } from "types/api"

export default function ListingSelect({
    url,
    value,
    ...props
}: {
    url: string
} & Partial<SelectProps>) {
    const { paginator, setPaginator } = usePaginator()
    const [options, setOptions] = useState<InputOptionType[]>([])

    const { isFetching } = useQuery<InputOptionType[]>({
        queryKey: [url, "listing-select", paginator.currentPage],
        refetchOnMount: false,
        queryFn: () =>
            api
                .getTyped<ListingResponse>(`listing/${url}`, {
                    count_on_page: paginator.pageSize,
                    page: paginator.currentPage,
                    sort: "name",
                    method: "asc",
                })
                .then((data) => {
                    setPaginator(data.pagination)
                    return data.entities.map((el) => ({
                        label: getFieldValue<string>(el.fields, "name") ?? "",
                        value: getFieldValue<Uuid>(el.fields, "id") ?? "",
                    }))
                }),
        onSuccess: (data) => {
            setOptions((prev) => [...prev, ...data])
        },
    })

    return (
        <Select
            options={options}
            size="large"
            value={value}
            {...props}
            loading={isFetching}
            getPopupContainer={(trigger) => {
                return trigger.parentElement
            }}
            onPopupScroll={(e) => {
                if (isFetching) return
                const scrollHeight = e.currentTarget.scrollHeight
                const body = e.currentTarget.children[0] as HTMLDivElement
                const scroll = body.children[0] as HTMLElement
                const style = getComputedStyle(scroll)
                const scrollY = style.transform.match(/\d+/g)?.pop()
                const value = scrollY ? +scrollY : 0
                if (
                    value * 2 > scrollHeight &&
                    paginator.allPages > paginator.currentPage
                ) {
                    setPaginator((prev) => ({
                        ...prev,
                        currentPage: prev.currentPage + 1,
                    }))
                }
            }}
        />
    )
}
